var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.initLoading,
          expression: "initLoading",
        },
      ],
      staticClass: "dialog-content",
      style: _vm.cssVar,
    },
    [
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "view-top" },
          [
            _c(
              "el-tooltip",
              {
                staticClass: "item",
                attrs: {
                  effect: "dark",
                  content: "打印纸张设置",
                  placement: "bottom",
                },
              },
              [
                _c("i", {
                  staticClass: "el-icon-tickets",
                  on: {
                    click: function ($event) {
                      return _vm.openPageDialog()
                    },
                  },
                }),
              ]
            ),
            _c(
              "el-tooltip",
              {
                staticClass: "item",
                attrs: {
                  effect: "dark",
                  content: "明细列表设置",
                  placement: "bottom",
                },
              },
              [
                _c("i", {
                  staticClass: "el-icon-s-grid",
                  on: {
                    click: function ($event) {
                      return _vm.openListDialog()
                    },
                  },
                }),
              ]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "view-bottom" }, [
          _c(
            "div",
            {
              style: {
                paddingLeft: _vm.getDpi(_vm.pageConfig.paddingLeft) + "px",
                paddingRight: _vm.getDpi(_vm.pageConfig.paddingRight) + "px",
                paddingTop: _vm.getDpi(_vm.pageConfig.paddingTop) + "px",
                paddingBottom: _vm.getDpi(_vm.pageConfig.paddingBottom) + "px",
                width:
                  (_vm.pageConfig.direction === 1
                    ? _vm.getDpi(_vm.pageConfig.width)
                    : _vm.getDpi(_vm.pageConfig.height)) + "px",
                height:
                  (_vm.pageConfig.direction === 1
                    ? _vm.getDpi(_vm.pageConfig.height)
                    : _vm.getDpi(_vm.pageConfig.width)) + "px",
              },
              attrs: { id: "print-container" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "top-view top-view-title",
                  style: {
                    height: _vm.pageConfig.topStyle.height + "px",
                  },
                },
                [
                  _c("div", [
                    _vm._v(
                      " " +
                        _vm._s(
                          "打印人：" +
                            _vm.$store.state.user.userinfo.nickName +
                            " 打印时间：" +
                            _vm.moment().format("yyyy-MM-DD HH:mm:ss")
                        ) +
                        " "
                    ),
                  ]),
                  _c("div", [
                    _vm._v(
                      " " +
                        _vm._s("" + (_vm.data.filters.topRightBillNo || "")) +
                        " "
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "20px",
                        "line-height": "20px",
                        "font-weight": "bold",
                        color: "#000000",
                        position: "absolute",
                        top: "0",
                        right: "0",
                        left: "0",
                        bottom: "0",
                        "text-align": "center",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.data.pageTitle || "") + " ")]
                  ),
                  _vm.data && _vm.data.filters
                    ? _c("div", [
                        _vm._v(
                          " " +
                            _vm._s("" + (_vm.data.filters.topRightText || "")) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
              !_vm.isShowPani
                ? [
                    _c(
                      "div",
                      {
                        style: {
                          marginRight: "2px",
                          width: "calc(100% - 1px)",
                          height: _vm.tableHeihgt + "px",
                        },
                      },
                      [
                        _vm.options
                          ? _c("TablePage", {
                              ref: "tablePage",
                              model: {
                                value: _vm.options,
                                callback: function ($$v) {
                                  _vm.options = $$v
                                },
                                expression: "options",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "top-view",
                        style: {
                          height: _vm.pageConfig.topStyle.height + "px",
                          borderTop: "1px solid #eeeeee",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.inPageNum + " - " + _vm.maxPage) +
                            " "
                        ),
                      ]
                    ),
                  ]
                : [
                    _c(
                      "div",
                      {
                        style: {
                          marginRight: "2px",
                          width: "calc(100% - 1px)",
                          height: _vm.tableHeihgt + "px",
                        },
                      },
                      [
                        _c("TablePage", {
                          ref: "tablePage",
                          model: {
                            value: _vm.myTableObj,
                            callback: function ($$v) {
                              _vm.myTableObj = $$v
                            },
                            expression: "myTableObj",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "button",
                      {
                        attrs: { disabled: _vm.myCurrentPage <= 1 },
                        on: { click: _vm.prevPage },
                      },
                      [_vm._v("上一页")]
                    ),
                    _c("span", [
                      _vm._v(
                        "第 " +
                          _vm._s(_vm.myCurrentPage) +
                          " 页 / 共 " +
                          _vm._s(_vm.totalPages) +
                          " 页"
                      ),
                    ]),
                    _c(
                      "button",
                      {
                        attrs: {
                          disabled: _vm.myCurrentPage >= _vm.totalPages,
                        },
                        on: { click: _vm.nextPage },
                      },
                      [_vm._v("下一页")]
                    ),
                  ],
            ],
            2
          ),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.$emit("dialogEvent", "dialogClose")
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.saveSetting()
                },
              },
            },
            [_vm._v("保存设置")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.printAll()
                },
              },
            },
            [_vm._v("打 印")]
          ),
        ],
        1
      ),
      _c("Dialog", {
        ref: "Dialog",
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }